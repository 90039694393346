
// import TopCard from "../components/TopCard";
import { getCompanyPortfolioQuery } from "~/helpers/apollo/apollo-company-query-helpers.js";
import CompanyPortfolioProperties from "~/graphql/Company/CompanyPortfolioProperties.gql";
import { getPropertyIcon as _getPropertyIcon } from "~/helpers/property-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import AddToListDialog from "~/components/list/AddToListDialog.vue";
import {
  PROPERTY_TYPE_MAIN_PROPERTY,
  PROPERTY_TYPE_CONDO,
  PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT,
  PROPERTY_TYPE_UNKNOWN,
  getPropertyType,
} from "~/helpers/property-helpers.js";
import {
  createListPropertiesSheetCompany,
  createListCondosSheetCompany,
  writeSheetsToXSLXAndSave
} from "~/helpers/download-helpers";

export default {
  components: {
    // TopCard,
    AddToListDialog,
  },

  inheritAttrs: false,

  data: function () {
    return {
      showAddToList: false,
      propertiesToSave: [],
      activeId: 0,
      activeProperties: [],
      loadingCompanyProperties: false,
      resultsPerPage: 10,
      pageChangeDebounce: null,
      pageChangeDebounceTime: 600,
      offset: 0,
      mainPropertiesKeys: [
        { val: true, name: this.$t("LIST_EXPORT_ADDRESS"), value: "addressText" },
        { val: true, name: this.$t("LIST_EXPORT_MATEJER"), value: "matEjerlav" },
        { val: true, name: this.$t("LIST_EXPORT_PROPTYPE"), value: "propertyType" },
        {
          val: true,
          name: this.$t("LIST_EXPORT_OLDEST_CONSTRUCTION_YEAR"),
          value: "oldestConstructionYear",
        },
        {
          val: true,
          name: this.$t("LIST_EXPORT_ALL_CONSTRUCTION_YEARS"),
          value: "allConstructionYears",
        },
        { val: true, name: this.$t("LIST_EXPORT_EXTENSION_YEAR"), value: "extensionYears" },
        { val: true, name: this.$t("LIST_EXPORT_DESC"), value: "description" },
        { val: true, name: this.$t("LIST_EXPORT_STATUS"), value: "status" },
        { val: true, name: this.$t("LIST_EXPORT_FLOORS"), value: "noOfFloors" },
        { val: true, name: this.$t("LIST_EXPORT_TOTALNOOFROOMS"), value: "noOfRooms" },
        // { val: true, name: this.$t("LIST_EXPORT_RESIDENTIALAREA"), value: "residentialArea" },
        { val: true, name: this.$t("LIST_EXPORT_BUILDINGAREA"), value: "buildingArea" },
        { val: true, name: this.$t("LIST_EXPORT_SITEAREA"), value: "siteArea" },
        { val: true, name: this.$t("LIST_EXPORT_COMMERCIALAREA"), value: "commercialArea" },
        { val: true, name: this.$t("LIST_EXPORT_WEIGHTED_AREA"), value: "weightedArea" },
        { val: true, name: this.$t("LIST_EXPORT_PUBVAL"), value: "publicValuation" },
        { val: true, name: this.$t("LIST_EXPORT_SALESDATE"), value: "latestSalesDate" },
        { val: true, name: this.$t("LIST_EXPORT_SALESSUM"), value: "latestSalesSum" },
        { val: true, name: this.$t("C_TRANS_CLAIMS"), value: "claims" },
      ],

      condosKeys: [
        { val: true, name: this.$t("LIST_EXPORT_ADDRESS"), value: "addressText" },
        { val: true, name: this.$t("LIST_EXPORT_PROPTYPE"), value: "propertyType" },
        { val: true, name: this.$t("LIST_EXPORT_CONSTRUCTION_YEAR"), value: "constructionYear" },
        { val: true, name: this.$t("LIST_EXPORT_EXTENSION_YEAR"), value: "extensionYears" },
        { val: true, name: this.$t("LIST_EXPORT_DESC"), value: "description" },
        { val: true, name: this.$t("LIST_EXPORT_STATUS"), value: "status" },
        { val: true, name: this.$t("LIST_EXPORT_FLOOR"), value: "floorNo" },
        { val: true, name: this.$t("LIST_EXPORT_NOOFROOMS"), value: "noOfRooms" },
        { val: true, name: this.$t("LIST_EXPORT_TOTALAREA"), value: "totalArea" },
        { val: true, name: this.$t("LIST_EXPORT_RESIDENTIALAREA"), value: "residentialArea" },
        { val: true, name: this.$t("LIST_EXPORT_COMMERCIALAREA"), value: "commercialArea" },
        { val: true, name: this.$t("LIST_EXPORT_WEIGHTED_AREA"), value: "weightedArea" },
        // { val: true, name: this.$t("LIST_EXPORT_INMAINPROPERTY"), value: "inMainProperty" },
        { val: true, name: this.$t("LIST_EXPORT_PUBVAL"), value: "publicValuation" },
        { val: true, name: this.$t("LIST_EXPORT_SALESDATE"), value: "latestSalesDate" },
        { val: true, name: this.$t("LIST_EXPORT_SALESSUM"), value: "latestSalesSum" },
        { val: true, name: this.$t("C_TRANS_CLAIMS"), value: "claims" },
      ],
    };
  },

  apollo: {
    companyPortfolio: getCompanyPortfolioQuery,
  },

  computed: {
    cvrNumber: function () {
      return parseInt(this.$route.params.id, 10);
    },
    loading: function () {
      return this.$apollo.queries.companyPortfolio.loading;
    },
    properties: function () {
      return this.companyPortfolio.flatMap((company) => company.properties).filter((property) => property != null);
    },
    totalResults: function () {
      return this.activeProperties?.length;
    },
    resultsCurrentPage: function () {
      return this.offset / this.resultsPerPage;
    },
  },

  watch: {
    companyPortfolio: function (data) {
      if (data.length > 0) {
        this.setActiveId(0, this.companyPortfolio[0].cvr);
      }
    },
  },

  methods: {
    getPropertyAddress,
    hasPurchaseSum(property) {
      return (
        property.ownerChanges != null &&
        property.ownerChanges.length > 0 &&
        property.ownerChanges.some((ownerChange) => ownerChange.transactionInfo != null && ownerChange.transactionInfo.totalPurchaseSum != null)
      );
    },

    getLatestPurchase(property) {
      if (property.ownerChanges == null || property.ownerChanges.length === 0) {
        return {
          date: null,
          price: null,
        };
      }

      let ownerChangesWithPriceSorted = [...property.ownerChanges]
        .filter((oc) => oc.transactionInfo != null && oc.transactionInfo.totalPurchaseSum != null)
        .sort((a, b) => new Date(b.handoverDate).getTime() - new Date(a.handoverDate).getTime());

      if (ownerChangesWithPriceSorted.length === 0) {
        return {
          date: null,
          price: null,
        };
      }

      let latestOwnerChangeWithPrice = ownerChangesWithPriceSorted[0];

      return {
        date: latestOwnerChangeWithPrice.handoverDate,
        price: latestOwnerChangeWithPrice.transactionInfo.totalPurchaseSum,
      };
    },

    getLatestPropertyValuation(property) {
      if (!property.valuations || property.valuations.lengths === 0) {
        return 0;
      }

      let properyValuationsSorted = [...property.valuations].sort((a, b) => b.year - a.year);

      return properyValuationsSorted[0]?.propertyValuation;
    },

    hasLink(property) {
      return property.bfeNumber != null;
    },

    savePropertyToList(property) {
      this.propertiesToSave = [property.bfeNumber];
      this.showAddToList = true;
    },

    saveAllPropertiesToList() {
      this.propertiesToSave = this.properties;
      this.showAddToList = true;
    },

    isPropertyInList(property) {
      return property.isPropertyOnList;
    },

    hasCondo(property) {
      return property.condo != null;
    },

    hasCadastral(property) {
      return property.plots != null && property.plots.some((plot) => plot != null && plot.matrikelNumber != null && plot.ownersGuildCode != null);
    },

    getPropertyTypeString(property) {
      switch (getPropertyType(property)) {
        case PROPERTY_TYPE_MAIN_PROPERTY:
          return this.$t("COMPANY_LABEL_TYPE_MAIN_PROPERTY");
        case PROPERTY_TYPE_CONDO:
          return this.$t("COMPANY_LABEL_TYPE_CONDO");
        case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
          return this.$t("COMPANY_LABEL_TYPE_BUILDING_ON_FOREIGN_PLOT");
        case PROPERTY_TYPE_UNKNOWN:
          return this.$t("COMPANY_LABEL_TYPE_UNKNOWN");
        default:
          return this.$t("COMPANY_LABEL_TYPE_UNKNOWN");
      }
    },

    setActiveId(id, cvrNumber) {
      this.offset = 0;
      this.activeId = id;
      this.loadingCompanyProperties = true;
      this.$apollo
        .query({
          query: CompanyPortfolioProperties,
          variables: { cvrNumber },
        })
        .then((resp) => {
          this.activeProperties = resp.data.companyByCvr.properties;
        })
        .finally(() => {
          this.loadingCompanyProperties = false;
        });
    },

    scrollToTop() {
      const element = document.querySelectorAll("div.composition-layout-paged-results")[0];

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    },

    async exportToExcel() {
      const queries = this.companyPortfolio
        .filter((c) => c.properties.length > 0)
        .map((x) => x.cvr)
        .map((cvrNumber) =>
          this.$apollo.query({
            query: CompanyPortfolioProperties,
            variables: { cvrNumber },
          })
        );

      const results = await Promise.all(queries);
      const allProperties = results.flatMap((result) => result.data.companyByCvr.properties);

      const properties = allProperties.filter((x) => x.plots);
      const condos = allProperties.filter((x) => x.condo);

      const propertiesSheet = createListPropertiesSheetCompany(
        properties || [],
        this.mainPropertiesKeys.filter((x) => x.val)
      );

      const condoSheet = createListCondosSheetCompany(
        condos || [],
        this.condosKeys.filter((x) => x.val)
      );

      writeSheetsToXSLXAndSave([propertiesSheet, condoSheet], `${this.cvrNumber}_property_export_${new Date().toISOString().split('T')[0]}`);
    },

    pageNext() {
      this.offset = (this.resultsCurrentPage + 1) * this.resultsPerPage;
      this.scrollToTop();
    },
    pagePrevious() {
      this.offset = (this.resultsCurrentPage - 1) * this.resultsPerPage;
      this.scrollToTop();
    },
    pageChange(n) {
      if (n == null) {
        return;
      }
      clearTimeout(this.pageChangeDebounce);
      this.pageChangeDebounce = setTimeout(() => {
        this.offset = (n - 1) * this.resultsPerPage;
        this.scrollToTop();
      }, this.pageChangeDebounceTime);
    },
    getPropertyIcon(property) {
      return _getPropertyIcon(property);
    },
    paginate(array, page_size, page_number) {
      return array.slice(page_number * page_size, (page_number + 1) * page_size);
    },
  },
};
